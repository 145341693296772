<template>
  <div class="py-16 mb-16">
    <v-sheet class="mb-6" color="surface">
      <v-container class="d-flex align-center">
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          class="rounded-lg mr-4"
          icon
          @click="drawer = !drawer"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-img
          v-if="$vuetify.breakpoint.mdAndUp"
          :src="
            !!localUser && localUser.avatar
              ? localUser.avatar
              : require('@/assets/utils/profile.png')
          "
          class="mr-6"
          max-width="96px"
          height="96px"
          contain
        />

        <div class="d-flex flex-column">
          <div class="text-h4 font-weight-bold mb-2">
            <span>{{ localUser.nickname }}</span>
          </div>

          <div class="d-flex">
            <v-icon color="primary" small left> mdi-circle </v-icon>

            <span class="text-body-1">
              {{
                localUser.school
                  ? localUser.school.name
                  : localUser.schoolComplementName
              }}
            </span>
          </div>
        </div>
      </v-container>
    </v-sheet>

    <v-container>
      <div class="d-flex">
        <v-navigation-drawer
          v-model="drawer"
          color="background elevation-0"
          :absolute="$vuetify.breakpoint.smAndDown"
          :permanent="$vuetify.breakpoint.mdAndUp"
          floating
        >
          <v-list nav>
            <router-link
              v-for="(item, i) in routes"
              v-show="!item.disabled"
              :key="i"
              v-slot="{ href, navigate, isActive }"
              :to="item.path"
              custom
            >
              <v-list-item
                :href="href"
                :disabled="item.disabled"
                @click="navigate"
              >
                <div
                  v-if="isActive"
                  class="mr-4 primary"
                  style="width: 4px; height: 32px"
                />

                <div class="d-flex align-center">
                  <v-icon left small v-text="item.icon" />

                  <span :class="isActive || 'subtext--text'">
                    {{ item.label }}
                  </span>
                </div>
              </v-list-item>
            </router-link>

            <v-divider />

            <v-list-item class="d-flex align-center" @click="exit()">
              <v-icon left small v-text="'mdi-logout'" />
              <span class="subtext--text"> Finalizar sessão </span>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <router-view />
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      drawer: true,
      routes: [
        {
          icon: "mdi-account",
          label: "Meu Perfil",
          path: "/account/profile",
        },
        {
          icon: "mdi-account-multiple",
          label: "Minhas Equipes",
          path: "/account/teams",
        },
        {
          icon: "mdi-shield",
          label: "Minhas Ligas",
          path: "/account/leagues",
        },
        {
          icon: "mdi-gamepad",
          label: "Meus Jogos",
          path: "/account/games",
        },
      ],
    };
  },

  computed: {
    ...mapState(["localUser"]),
  },

  methods: {
    async exit() {
      this.$root.$emit("logoff");
    },
  },
};
</script>

<style></style>
